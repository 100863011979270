export const isNotNull = <T>(value: T | null): value is T => {
  return value !== null
}

export const createNewObject = () => {
  return Object.create(null)
}

export const getLocalDate = (date: string | undefined) => {
  return date ? new Date(date).toLocaleString() : null
}
