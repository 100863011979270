import { type AttributeFieldNames } from './types'

export const APP_NAME = 'SKU HUB'
// TODO: Ссылка на публичный каталог, скорее всего будет в ENV
export const APP_PUBLIC_URL = 'https://google.com/search?q='

export const SIMPLE_LAYOUT_CONTENT_WIDTH = 268
export const SIMPLE_LAYOUT_LOGO_WIDTH = 210

export const SIDEBAR_OPEN_MIN_WIDTH = 200
export const SIDEBAR_CLOSED_MIN_WIDTH = 59
export const SIDEBAR_OPEN_LOGO_WIDTH = 118
export const SIDEBAR_CLOSED_LOGO_WIDTH = 21

export const ELEMENTS_XS_WIDTH = 240
export const ELEMENTS_S_WIDTH = 300
export const ELEMENTS_M_WIDTH = 400

export const CATALOG_CARD_SKELETON_AMOUNT = 9
export const CATALOG_CARD_IMAGE_SIZE = 200
export const CATALOG_TABLE_IMAGE_SIZE = 50

export enum CATALOG_VIEW {
  CARDS = 'Сетка',
  TABLE = 'Таблица',
}

export enum MESSAGE {
  ADD_GROUP = 'Создать группу',
  CREATE = 'Создать',
  SAVE = 'Сохранить',
  EDIT = 'Редактировать',
  EMPTY = 'Ничего не найдено',
  EMPTY_NAME = 'Без названия',
  EMPTY_VALUE = '–',
  LOADING = 'Загрузка…',
  BACK = 'Назад',
  SERVER_ERROR = 'Ошибка сервера, попробуйте ещё раз',
  ATTRIBUTE_EMPTY_ERROR = 'Введите значение атрибута',
  ENTER_NAME = 'Введите название',
  ENTER_SKU = 'Введите артикул',
  SKU_SKU = 'Ариткул товара',
  SKU_NAME = 'Название товара',
  FIND_ATTRIBUTE = 'Найти атрибут',
  FIND_SKU = 'Найти товар',
  DELETE_SELECTED = 'Удалить выбранные',
}

export enum ATTRIBUTE_LABELS {
  SKU = 'SKU',
  NAME = 'PN',
  BRAND = 'Brand',
  PREVIEW = 'Preview',
}

export const ATTRIBUTE_FIELD_NAMES: AttributeFieldNames = {
  uuid: 'UUID',
  is_enabled: 'Активен',
  is_deleted: 'Удален',
  name: 'Название',
  attribute_type: 'Тип',
  is_featured: 'Is featured',
  is_required: 'Обязательный',
  description: 'Описание',
  is_public: 'Публичный',
  object_group: 'Группа',
  is_sku: 'SKU',
  is_name: 'Название продукта',
  is_brand: 'Бренд',
  is_preview: 'Превью',
  slug: 'Slug',
  updated_at: 'Обновлен',
  created_at: 'Создан',
  created_by: 'Создатель',
}

export enum ATTRIBUTE_FIELDS {
  UUID = 'uuid',
  IS_ENABLED = 'is_enabled',
  IS_DELETED = 'is_deleted',
  NAME = 'name',
  ATTRIBUTE_TYPE = 'attribute_type',
  IS_FEATURED = 'is_featured',
  IS_REQUIRED = 'is_required',
  DESCRIPTION = 'description',
  IS_PUBLIC = 'is_public',
  OBJECT_GROUP = 'object_group',
  IS_SKU = 'is_sku',
  IS_NAME = 'is_name',
  IS_BRAND = 'is_brand',
  IS_PREVIEW = 'is_preview',
  SLUG = 'slug',
  UPDATED_AT = 'updated_at',
  CREATED_AT = 'created_at',
  CREATED_BY = 'created_by',
}

export enum ATTRIBUTE_PARAMS {
  ATTRIBUTE_TYPE = 'attribute_type',
  CATEGORY = 'category',
  CATEGORY_ISNULL = 'category__isnull',
  MODEL = 'model',
  OBJECT_GROUP = 'object_group',
  OBJECT_GROUP_ISNULL = 'object_group__isnull',
  ORDERING = 'ordering',
  PAGE = 'page',
  SEARCH = 'search',
  SIZE = 'size',
}
