import {
  API_ATTRIBUTE_VALUES,
  API_QUERY_KEY,
  buildRequestApi,
  HTTP_METHOD,
  queryClient,
} from '@shared/api'
import { type ApiAttribute } from '@shared/api/types'
import { MESSAGE } from '@shared/config'
import { useDialog } from '@shared/hooks'
import { useMutation } from '@tanstack/react-query'
import { useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'

import { type EditAttributeFormInputs, type UseEditAttributeArgs } from './types'

export const useEditAttribute = ({ defaultValues }: UseEditAttributeArgs) => {
  const { id: skuIdParam } = useParams()
  const skuId = parseInt(skuIdParam!)

  const { isOpen, handleOpenDialog: showModal, handleCloseDialog } = useDialog()
  const methods = useForm<EditAttributeFormInputs>({ defaultValues })

  const closeModal = () => {
    handleCloseDialog()
    methods.reset()
  }

  const mutation = useMutation({
    mutationFn: ({ attributeId, attributeValueId, value }: EditAttributeFormInputs) => {
      // Если у атрибута нет значения, создаём для него attribute_value
      if (!attributeValueId) {
        const newAttributeValue = buildRequestApi<ApiAttribute>(
          `${API_ATTRIBUTE_VALUES}`,
          'Ошибка создания значения атрибута',
          {
            method: HTTP_METHOD.POST,
            body: {
              object_id: skuId,
              value: value,
              attribute: attributeId,
            },
          },
        )

        return newAttributeValue()
      }

      // Если в форме стёрли значениее, удаляем attribute_value для атрибута
      if (!value) {
        const deleteAttributeValue = buildRequestApi(
          `${API_ATTRIBUTE_VALUES}${attributeValueId}/`,
          'Ошибка удаления значения атрибута',
          {
            method: HTTP_METHOD.DELETE,
            body: {
              id: attributeValueId,
            },
          },
        )

        return deleteAttributeValue()
      }

      // Обновляем значение attribute_value для атрибута
      const editAttributeValue = buildRequestApi<ApiAttribute>(
        `${API_ATTRIBUTE_VALUES}${attributeValueId}/`,
        'Ошибка редактирования значения атрибута',
        {
          method: HTTP_METHOD.PATCH,
          body: {
            value: value,
          },
        },
      )

      return editAttributeValue()
    },
    onError: () => {
      methods.setError('root', {
        type: 'manual',
        message: MESSAGE.SERVER_ERROR,
      })
    },
    onSuccess: () => {
      queryClient.resetQueries({ queryKey: [API_QUERY_KEY.ATTRIBUTE_GROUP_LIST] })
      queryClient.resetQueries({ queryKey: [API_QUERY_KEY.SKU_LIST, skuId] })
      closeModal()
    },
  })

  const saveAttribute = methods.handleSubmit((data) => {
    const { attributeId, attributeValueId } = data

    mutation.mutate({
      attributeId,
      attributeValueId,
      // @ts-ignore TODO: разобраться с обращением
      value: data[`${attributeId}`] || null,
    })
  })

  return {
    isOpen,
    showModal,
    closeModal,
    methods,
    saveAttribute,
    isSubmitting: mutation.isPending,
  }
}
