import { EuiFormRow, EuiSuperSelect } from '@elastic/eui'
import { isFieldError } from '@shared/lib'
import { useFormContext } from 'react-hook-form'

import { type SelectInputProps } from './types'

const SelectInput = <T,>({
  label = '',
  name,
  placeholder = '',
  inputProps,
}: SelectInputProps<T>) => {
  const {
    formState: { errors },
    setValue,
    watch,
  } = useFormContext()

  const error = errors?.[name]

  const value = watch(name)

  return (
    <EuiFormRow label={label} isInvalid={!!error} error={isFieldError(error) && error.message}>
      <EuiSuperSelect<T>
        placeholder={placeholder}
        isInvalid={!!error}
        name={name}
        valueOfSelected={value}
        onChange={(value) => {
          setValue(name, value)
        }}
        options={inputProps?.options || []}
        {...inputProps}
      />
    </EuiFormRow>
  )
}

export { SelectInput }
