import {
  $api,
  API_QUERY_KEY,
  API_QUERY_KEY_OPENAPI,
  queryClient,
  useAttributeListApi,
} from '@shared/api'
import { useRouterParams } from '@shared/hooks'

const useRemoveAttributeGroups = () => {
  const { hasParam, getParamValues } = useRouterParams()
  const isDisabledButton = !hasParam('selected')

  const groupsForRemove = getParamValues('selected').map((groupId) => {
    return parseInt(groupId)
  })

  const attributesInGroups = useAttributeListApi({
    object_group: groupsForRemove,
  })

  const clearAttributes = $api.useMutation('patch', '/attributes/{id}/', {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: API_QUERY_KEY_OPENAPI.ATTRIBUTE_LIST })
      queryClient.invalidateQueries({ queryKey: [API_QUERY_KEY.ATTRIBUTE_GROUP_LIST] })
    },
  })

  const removeGroups = $api.useMutation('patch', '/object_groups/{id}/', {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [API_QUERY_KEY.ATTRIBUTE_GROUP_LIST] })
    },
  })

  // TODO: Заменить когда появится эндпоинт удаления группы атрибутов
  const removeFromGroups = () => {
    attributesInGroups.data?.results.map(({ id }) => {
      clearAttributes.mutate({
        params: {
          path: {
            id,
          },
        },
        body: {
          object_group: null,
        },
      })
    })

    groupsForRemove.map((id) => {
      removeGroups.mutate({
        params: {
          path: {
            id,
          },
        },
        body: {
          is_deleted: true,
        },
      })
    })
  }

  return {
    isDisabledButton,
    isSubmitting: removeGroups.isPending || clearAttributes.isPending,
    removeFromGroups,
  }
}

export { useRemoveAttributeGroups }
