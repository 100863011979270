import { EuiForm } from '@elastic/eui'
import { useAddSku } from '@entities/catalog'
import { MESSAGE } from '@shared/config'
import { FormError, TextInput } from '@shared/ui/form'
import { Modal } from '@shared/ui/modal'
import { FormProvider } from 'react-hook-form'

import { type AddSkuProps } from './types'

const AddSku: ReactFc<AddSkuProps> = ({ isOpen, onClose }) => {
  const { handleModalClose, methods, handleSubmit, isLoading } = useAddSku(onClose)

  return (
    <FormProvider {...methods}>
      <Modal
        title="Новый товар"
        onClose={handleModalClose}
        onSubmit={handleSubmit}
        isOpen={isOpen}
        isSubmitting={isLoading}
      >
        <EuiForm component="form">
          <TextInput
            name="value_name"
            placeholder={MESSAGE.SKU_NAME}
            registerOptions={{ required: MESSAGE.ENTER_NAME }}
            inputProps={{
              isLoading: isLoading,
            }}
            isRequired
          />

          <TextInput
            name="value_sku"
            placeholder={MESSAGE.SKU_SKU}
            registerOptions={{ required: MESSAGE.ENTER_SKU }}
            inputProps={{
              isLoading: isLoading,
            }}
            isRequired
          />
          <FormError />
        </EuiForm>
      </Modal>
    </FormProvider>
  )
}

export { AddSku }
