import { API_QUERY_KEY, ATTRIBUTE_FLAGS, extractAttribute, useQuery } from '@shared/api'
import { ROUTES } from '@shared/config'

import { type ApiSkuItem, type Sku, type UseSkuListApiArg } from '../types'
import { getSkuList } from './get-sku-list'

export const useSkuListApi = (query: UseSkuListApiArg = {}) => {
  const skuList = useQuery<ApiSkuItem[]>([API_QUERY_KEY.SKU_LIST, query], () => {
    return getSkuList(query)
  })

  const data: Sku[] = skuList.data?.map((sku) => {
    const href = ROUTES.CATALOG.SKU.buildPath({ id: sku.id })
    const preview = extractAttribute(sku, ATTRIBUTE_FLAGS.PREVIEW)?.file?.preview || ''

    const attributes = Object.fromEntries(
      sku.attributes.map((attribute) => {
        return [attribute.slug, attribute.value]
      }),
    )

    return {
      ...sku,
      ...attributes,
      href,
      preview,
    }
  }) as Sku[]

  return {
    ...skuList,
    data,
  }
}
