import { EuiButtonIcon, EuiFlexGroup, EuiFlexItem, EuiHorizontalRule } from '@elastic/eui'
import {
  AttributeFormField,
  useAttributeGroupsList,
  useAttributeTypeOptions,
} from '@entities/attributes'
import { type AttributeGroup } from '@entities/attributes/types'
import { useSkuApi } from '@entities/catalog'
import { $api, API_QUERY_KEY, queryClient } from '@shared/api'
import { type ApiAttribute } from '@shared/api/types'
import { ATTRIBUTE_PARAMS, MESSAGE } from '@shared/config'
import { useRouterParams, useSelectableFilter } from '@shared/hooks'
import { convertSelectToPopoverOptions } from '@shared/lib'
import { Search, SelectablePopover } from '@shared/ui'
import { Accordion } from '@shared/ui/accordion'
import { Fragment, useEffect } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'

const SkuAttributesTab: ReactFc = () => {
  const { id: skuId } = useParams()
  const { data: sku, isLoading: isSkuLoading } = useSkuApi(skuId)
  const { data: attributeGroups, isLoading: isAttributesGroupsLoading } = useAttributeGroupsList()

  const isLoading = isSkuLoading || isAttributesGroupsLoading

  // Фильтры и поиск
  const { hasParam, getParamValues, params: query } = useRouterParams()
  const { typeOptions } = useAttributeTypeOptions()
  const attributeTypeOptions = convertSelectToPopoverOptions(typeOptions)

  const {
    popoverOptions: popoverTypeOptions,
    checkParams: checkTypeParams,
    handleFilterChange: handleTypeFilterChange,
    handleFilterReset: handleTypeFilterReset,
  } = useSelectableFilter({ param: ATTRIBUTE_PARAMS.ATTRIBUTE_TYPE, options: attributeTypeOptions })

  useEffect(checkTypeParams, [])

  const doesAttributeMatchTypeFilters = (attribute: ApiAttribute) => {
    return hasParam(ATTRIBUTE_PARAMS.ATTRIBUTE_TYPE)
      ? getParamValues(ATTRIBUTE_PARAMS.ATTRIBUTE_TYPE).includes(
          attribute.attribute_type?.toString() || '',
        )
      : true
  }

  const isNameMatchSearch = (attribute: ApiAttribute) => {
    const attributeName = attribute.name?.toLocaleLowerCase()
    const searchValue = query.search?.toLocaleLowerCase()

    return query.search ? attributeName.includes(searchValue) : true
  }

  const getNotEmptyAttributeGroups = (attributeGroups: AttributeGroup[]) => {
    return attributeGroups.filter((group) => {
      const hasAttributes = Boolean(
        sku?.attributes.find((attribute) => {
          return attribute.object_group === group.id && isNameMatchSearch(attribute)
        }),
      )

      return hasAttributes
    })
  }

  const notEmptyAttributeGroups = getNotEmptyAttributeGroups(attributeGroups)

  // Сохранение
  const methods = useForm()

  useEffect(() => {
    if (sku?.attributes) {
      const defaultValues = sku.attributes.reduce((acc, attribute) => {
        // @ts-ignore
        acc[attribute.value_id] = attribute.value

        return acc
      }, {})

      // Обновляем значения формы
      methods.reset(defaultValues)
    }
  }, [isLoading])

  // Отслеживаем изменения всех полей формы
  const watchedFields = methods.watch()

  const changed = Object.keys(watchedFields).filter((field) => {
    return methods.formState.dirtyFields[field]
  })

  const editAttrValue = $api.useMutation('patch', '/attribute_values/{id}/')
  const addAttrValue = $api.useMutation('post', '/attribute_values/')
  const deleteAttrValue = $api.useMutation('delete', '/attribute_values/{id}/')

  const isSubmitting =
    editAttrValue.isPending || addAttrValue.isPending || deleteAttrValue.isPending

  const onSuccess = () => {
    queryClient.refetchQueries({ queryKey: [API_QUERY_KEY.ATTRIBUTE_GROUP_LIST] })
    queryClient.refetchQueries({ queryKey: [API_QUERY_KEY.SKU_LIST, skuId && parseInt(skuId)] })
    methods.reset()
  }

  const saveHandler = () => {
    changed.map((attributeId) => {
      const attribute = sku?.attributes.find(({ id }) => {
        return id === parseInt(attributeId)
      })

      if (!watchedFields[attributeId] && attribute) {
        deleteAttrValue.mutate(
          {
            params: {
              path: {
                id: attribute.value_id,
              },
            },
          },
          { onSuccess },
        )

        return
      }

      if (attribute?.value_id && skuId) {
        editAttrValue.mutate(
          {
            params: {
              path: {
                id: attribute?.value_id,
              },
            },
            body: {
              value: watchedFields[attributeId],
            },
          },
          { onSuccess },
        )

        return
      }

      if (!attribute?.value_id && watchedFields[attributeId] && skuId) {
        addAttrValue.mutate(
          {
            // @ts-ignore: TODO: Разобраться с readonly атрибутами
            body: {
              object_id: parseInt(skuId),
              attribute: parseInt(attributeId),
              value: watchedFields[attributeId],
            },
          },
          { onSuccess },
        )

        return
      }
    })
  }

  return (
    <>
      <FormProvider {...methods}>
        <EuiFlexGroup gutterSize="m">
          <EuiFlexItem grow={false}>
            <Search searchParam="search" placeholder={MESSAGE.FIND_ATTRIBUTE} />
          </EuiFlexItem>

          <SelectablePopover
            label="Тип атрибутов"
            options={popoverTypeOptions}
            onChange={handleTypeFilterChange}
            searchable={false}
            onResetClick={handleTypeFilterReset}
          />

          {changed.length > 0 && (
            <EuiFlexItem>
              <EuiFlexGroup justifyContent="flexEnd">
                <EuiButtonIcon
                  iconType="save"
                  size="s"
                  color="primary"
                  display="fill"
                  aria-label="Сохранить"
                  onClick={saveHandler}
                  isLoading={isSubmitting}
                />
              </EuiFlexGroup>
            </EuiFlexItem>
          )}
        </EuiFlexGroup>

        <EuiHorizontalRule margin="s" />

        {notEmptyAttributeGroups?.map((group, index) => {
          return (
            <Fragment key={`group-${group.id}`}>
              <Accordion
                id={group.id.toString()}
                buttonContent={group.name}
                paddingSize="l"
                initialIsOpen={index == 0 && !isLoading}
                isLoading={isLoading}
              >
                <EuiFlexGroup direction="column" gutterSize="s">
                  {sku?.attributes
                    .filter((attribute) => {
                      return (
                        attribute.object_group === group.id &&
                        doesAttributeMatchTypeFilters(attribute) &&
                        isNameMatchSearch(attribute)
                      )
                    })
                    .map((attribute) => {
                      return (
                        <EuiFlexGroup
                          key={`attribute-${attribute.id}`}
                          alignItems="center"
                          justifyContent="spaceBetween"
                        >
                          <EuiFlexItem grow={1}>{attribute.name}</EuiFlexItem>

                          <EuiFlexItem grow={3}>
                            <AttributeFormField
                              {...attribute}
                              isSubmitting={isSubmitting}
                              compressed
                            />
                          </EuiFlexItem>
                        </EuiFlexGroup>
                      )
                    })}
                </EuiFlexGroup>
              </Accordion>

              <EuiHorizontalRule margin="s" />
            </Fragment>
          )
        })}
      </FormProvider>
    </>
  )
}

export { SkuAttributesTab }
