import { API_ATTRIBUTE_LIST, buildRequestApi, HTTP_METHOD } from '@shared/api'
import { type ApiAttribute } from '@shared/api/types'

const updateAttribute = (id: string, body: ApiAttribute) => {
  return buildRequestApi<ApiAttribute>(
    `${API_ATTRIBUTE_LIST}${id}/`,
    'Ошибка при обновлении атрибута',
    {
      method: HTTP_METHOD.PATCH,
      body,
    },
  )()
}

export { updateAttribute }
