import { EuiForm } from '@elastic/eui'
import { useUpdateAttributes } from '@entities/attributes'
import { useCategoryOptions } from '@entities/catalog'
import { type ApiAttribute } from '@shared/api/types'
import { useRouterParams } from '@shared/hooks'
import { FormError, SelectInput } from '@shared/ui/form'
import { Modal } from '@shared/ui/modal'
import { Controller, FormProvider, useForm } from 'react-hook-form'

import { type LinkAttributeToCategoryProps } from './types'

const LinkAttributeToCategory: ReactFc<LinkAttributeToCategoryProps> = ({ isOpen, onClose }) => {
  const methods = useForm<ApiAttribute>()
  const { getParamValues } = useRouterParams()

  const attributeIds = getParamValues('selected')

  const { categoryOptions, isLoading: isCategoryOptionsLoading } = useCategoryOptions()

  const {
    mutation: { mutateAsync },
    isSubmitting,
  } = useUpdateAttributes({ attributeIds, methods })

  const onSubmit = (data: ApiAttribute) => {
    mutateAsync(data.category ? data : ({ category: null } as ApiAttribute)).then(() => {
      methods.reset()
      onClose()
    })
  }

  return (
    <FormProvider {...methods}>
      <Modal
        title="Новый атрибут"
        submitButtonText="Привязать"
        onClose={onClose}
        onSubmit={methods.handleSubmit(onSubmit)}
        isOpen={isOpen}
        isSubmitting={isSubmitting}
      >
        <EuiForm component="form">
          <Controller
            name="category"
            control={methods.control}
            rules={{ required: 'Не выбрана категория' }}
            render={() => {
              return (
                <SelectInput
                  name="category"
                  placeholder="Выбрать категорию"
                  label="Категории"
                  inputProps={{
                    options: categoryOptions,
                    isLoading: isCategoryOptionsLoading,
                  }}
                />
              )
            }}
          />
          <FormError />
        </EuiForm>
      </Modal>
    </FormProvider>
  )
}

export { LinkAttributeToCategory }
