import { type EuiContextMenuPanelDescriptor } from '@elastic/eui'
import { AddSku } from '@features/catalog'
import { useDialog } from '@shared/hooks'
import { ContextMenu } from '@shared/ui/context-menu'

const CatalogActions: ReactFc = () => {
  const {
    isOpen: isAddSkuModalOpen,
    handleOpenDialog: showAddSkuModal,
    handleCloseDialog: closeAddSkuModal,
  } = useDialog()

  const panels: EuiContextMenuPanelDescriptor[] = [
    {
      id: 0,
      items: [
        {
          name: 'Новый товар',
          icon: 'plus',
          onClick: showAddSkuModal,
        },
      ],
    },
  ]

  return (
    <>
      <ContextMenu panels={panels} startIcon="gear" />
      <AddSku isOpen={isAddSkuModalOpen} onClose={closeAddSkuModal} />
    </>
  )
}

export { CatalogActions }
