import { useQuery, type UseQueryResult } from '@tanstack/react-query'

import { type API_OBJECT_GROUP, type API_QUERY_KEY } from '../constans'
import { type ApiObjectGroup } from '../types'
import { getObjectGroupList } from './get-object-group-list'

export const useObjectGroupListApi = (
  groupType: API_OBJECT_GROUP,
  queryKey: API_QUERY_KEY[],
): UseQueryResult<ApiObjectGroup[]> => {
  const getFilteredGroupList = async (groupType: API_OBJECT_GROUP): Promise<ApiObjectGroup[]> => {
    const objectGroups = await getObjectGroupList()

    return objectGroups.filter((objectGroup) => {
      // TODO: Убрать objectGroup.is_deleted когда заработает фильтрация в API
      return objectGroup.type === groupType && !objectGroup.is_deleted
    })
  }

  return useQuery<ApiObjectGroup[]>({
    queryKey: queryKey,
    queryFn: () => {
      return getFilteredGroupList(groupType)
    },
  })
}
