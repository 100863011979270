import { EuiHeader, EuiHeaderSection, EuiHeaderSectionItem } from '@elastic/eui'
import { AddAttributeToGroup, RemoveAttributeFromGroup } from '@features/attributes'
import { MESSAGE } from '@shared/config'
import { Search } from '@shared/ui'
import { noShadow } from '@shared/ui/styles'

export const AttributeGroupHeader: ReactFc = () => {
  return (
    <EuiHeader css={noShadow}>
      <EuiHeaderSection>
        <EuiHeaderSectionItem>
          <Search searchParam="search" placeholder={MESSAGE.FIND_ATTRIBUTE} />
        </EuiHeaderSectionItem>
      </EuiHeaderSection>

      <EuiHeaderSectionItem>
        <RemoveAttributeFromGroup />
        <AddAttributeToGroup />
      </EuiHeaderSectionItem>
    </EuiHeader>
  )
}
