import { type ApiCategoryItem, type Category } from '@entities/catalog'
import { ATTRIBUTE_FLAGS, extractAttribute } from '@shared/api'
import { MESSAGE } from '@shared/config'

export const extractCategoryData = (apiData: ApiCategoryItem[]): Category[] => {
  const categoryMap = new Map<number, Category>()

  // Инициализируем Category объекты и заполняем Map если есть имя
  apiData?.forEach((item) => {
    const name = extractAttribute(item, ATTRIBUTE_FLAGS.NAME)?.value || MESSAGE.EMPTY_NAME

    if (name) {
      const { id, skus_count, object_group, parent } = item

      categoryMap.set(id, {
        id,
        name,
        skus_count,
        object_group,
        parent,
        childNodes: [],
        isExpanded: false,
      })
    }
  })

  // Построение дерева категорий и заполнение childNodes
  categoryMap.forEach((category) => {
    if (category.parent !== null) {
      const parentCategory = categoryMap.get(category.parent)

      if (parentCategory) {
        parentCategory.childNodes = parentCategory.childNodes.concat(category.id)
        parentCategory.skus_count += category.skus_count
      }
    }
  })

  // Функция для рекурсивного сбора всех childNodes
  const collectAllChildNodes = (categoryId: number, visited = new Set<number>()): Set<number> => {
    const category = categoryMap.get(categoryId)
    if (!category) return visited

    category.childNodes.forEach((childId) => {
      if (!visited.has(childId)) {
        visited.add(childId)
        collectAllChildNodes(childId, visited)
      }
    })

    return visited
  }

  // Обновление childNodes для всех категорий
  categoryMap.forEach((category) => {
    category.childNodes = Array.from(collectAllChildNodes(category.id))
  })

  return Array.from(categoryMap.values())
}
