import { EuiFlexGroup, EuiFlexItem, EuiPanel, EuiTab, EuiTabs, EuiText } from '@elastic/eui'
import { minHFull } from '@shared/ui/styles'
import { useMemo, useState } from 'react'

import { SkuAttributesTab } from '../sku-attribute-tab'

// TODO: Раскидать по FSD
const tabs = [
  {
    id: 'attributes',
    name: 'Атрибуты',
    content: <SkuAttributesTab />,
  },
  {
    id: 'imagesAndFiles',
    name: 'Изображения и файлы',
    content: (
      <EuiText>
        <p>Контент Изображения и файлы</p>
      </EuiText>
    ),
  },
  {
    id: 'taxonomy',
    name: 'Таксономия',
    content: (
      <EuiText>
        <p>Контент Таксономия</p>
      </EuiText>
    ),
  },
  {
    id: 'categories',
    name: 'Категории',
    content: (
      <EuiText>
        <p>Контент Категории</p>
      </EuiText>
    ),
  },
  {
    id: 'certificates',
    name: 'Сертификаты',
    content: (
      <EuiText>
        <p>Контент Сертификаты</p>
      </EuiText>
    ),
  },
  {
    id: 'relatedProducts',
    name: 'Связанные товары',
    content: (
      <EuiText>
        <p>Контент Связанные товары</p>
      </EuiText>
    ),
  },
  {
    id: 'bundles',
    name: 'Комплекты',
    content: (
      <EuiText>
        <p>Контент Комплекты</p>
      </EuiText>
    ),
  },
]

export const SkuTabs: ReactFc = () => {
  const [selectedTabId, setSelectedTabId] = useState('attributes')

  const selectedTabContent = useMemo(() => {
    return tabs.find((obj) => {
      return obj.id === selectedTabId
    })?.content
  }, [selectedTabId])

  const onSelectedTabChanged = (id: string) => {
    setSelectedTabId(id)
  }

  const renderTabs = () => {
    return tabs.map((tab, index) => {
      return (
        <EuiTab
          key={index}
          onClick={() => {
            return onSelectedTabChanged(tab.id)
          }}
          isSelected={tab.id === selectedTabId}
        >
          {tab.name}
        </EuiTab>
      )
    })
  }

  return (
    <EuiPanel paddingSize="none">
      <EuiFlexGroup direction="column" gutterSize="none" css={minHFull}>
        <EuiFlexItem grow={false}>
          <EuiPanel hasShadow={false}>
            <EuiTabs>{renderTabs()}</EuiTabs>
          </EuiPanel>
        </EuiFlexItem>

        <EuiFlexItem>
          <EuiPanel color="subdued">{selectedTabContent}</EuiPanel>
        </EuiFlexItem>
      </EuiFlexGroup>
    </EuiPanel>
  )
}
