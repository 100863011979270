import { EuiFlexGroup, EuiFlexItem, EuiForm, EuiListGroupItem } from '@elastic/eui'
import { AttributeFormField } from '@entities/attributes'
import { CATALOG_TABLE_IMAGE_SIZE, MESSAGE } from '@shared/config'
import { FormError } from '@shared/ui/form'
import { Image } from '@shared/ui/image'
import { Modal } from '@shared/ui/modal'
import { textRight } from '@shared/ui/styles'
import { FormProvider } from 'react-hook-form'

import { useEditAttribute } from '../model'
import { type SkuAttributesItemProps } from './types'

const SkuAttributesListItem: ReactFc<SkuAttributesItemProps> = (attribute) => {
  const { isOpen, showModal, closeModal, methods, saveAttribute, isSubmitting } = useEditAttribute({
    defaultValues: {
      attributeId: attribute.id,
      attributeValueId: attribute.value_id,
      value: attribute.value,
    },
  })

  return (
    <EuiListGroupItem
      key={attribute.id}
      size="s"
      wrapText
      label={
        <EuiFlexGroup gutterSize="s" justifyContent="spaceBetween">
          <EuiFlexItem>{attribute.name}</EuiFlexItem>

          {attribute.is_preview && attribute.file ? (
            <Image
              src={attribute.file.preview}
              width={CATALOG_TABLE_IMAGE_SIZE}
              height={CATALOG_TABLE_IMAGE_SIZE}
              alt={attribute.name}
            />
          ) : (
            <EuiFlexItem css={textRight}>{attribute.value || '–'}</EuiFlexItem>
          )}

          <FormProvider {...methods}>
            <Modal
              title="Редактировать атрибут"
              onClose={closeModal}
              submitButtonText={MESSAGE.SAVE}
              onSubmit={saveAttribute}
              isOpen={isOpen}
              isSubmitting={isSubmitting}
            >
              <EuiForm component="form" onSubmit={saveAttribute}>
                <AttributeFormField {...attribute} isSubmitting={isSubmitting} />

                <FormError />
              </EuiForm>
            </Modal>
          </FormProvider>
        </EuiFlexGroup>
      }
      extraAction={{
        color: 'primary',
        iconType: 'pencil',
        alwaysShow: true,
        'aria-label': MESSAGE.EDIT,
        onClick: showModal,
        disabled: !attribute.is_editable,
      }}
    />
  )
}

export { SkuAttributesListItem }
