import {
  EuiButtonEmpty,
  EuiContextMenu,
  EuiFlexGroup,
  EuiFlexItem,
  EuiIcon,
  EuiPopover,
  EuiText,
} from '@elastic/eui'
import { useToggle } from '@shared/hooks'
import { useMemo } from 'react'

import { type ContextMenuProps, type HandleMenuItemClickArgument } from './types'

const ContextMenu: ReactFc<ContextMenuProps> = ({
  menuButtonText = '',
  startIcon = '',
  endIcon = '',
  panels,
}) => {
  const [isOpen, toggleIsOpen] = useToggle()

  const menuButton = (
    <EuiButtonEmpty onClick={toggleIsOpen} color="primary" size="s">
      <EuiFlexGroup alignItems="center" gutterSize="s">
        {startIcon && (
          <EuiFlexItem grow={false}>
            <EuiIcon type={startIcon} />
          </EuiFlexItem>
        )}

        <EuiFlexItem grow={false}>
          <EuiText size="s">{menuButtonText}</EuiText>
        </EuiFlexItem>

        {endIcon && (
          <EuiFlexItem grow={false}>
            <EuiIcon type={endIcon} />
          </EuiFlexItem>
        )}
      </EuiFlexGroup>
    </EuiButtonEmpty>
  )

  // если у пункта меню уже есть обрабочик нажатия, сначала он отработает, потом меню закроется
  const handleMenuItemClick = (onClickAction: HandleMenuItemClickArgument) => {
    return () => {
      onClickAction()
      toggleIsOpen()
    }
  }

  // каждому пункуту меню задаю onClick, чтобы меню закрывалось после нажатия
  const newPanels = useMemo(() => {
    return panels.map((panel) => {
      panel.items?.map((item) => {
        if (item.panel) return item

        const action = item.onClick

        item.onClick = action
          ? handleMenuItemClick(action as HandleMenuItemClickArgument)
          : toggleIsOpen

        return item
      })

      return panel
    })
  }, [panels])

  return (
    <EuiPopover
      button={menuButton}
      isOpen={isOpen}
      closePopover={toggleIsOpen}
      panelPaddingSize="none"
      anchorPosition="downLeft"
      hasArrow={false}
    >
      <EuiContextMenu initialPanelId={0} panels={newPanels} />
    </EuiPopover>
  )
}

export { ContextMenu }
