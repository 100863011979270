import { EuiIcon } from '@elastic/eui'
import { PAGE_TITLES, ROUTES } from '@shared/config'

import { createItem } from './create-item'

export const sidebarMenuItems = [
  {
    id: 'menu',
    name: 'МЕНЮ',
    items: [
      {
        id: 'all',
        name: 'Все товары',
        icon: <EuiIcon type="package" />,
        forceOpen: true,
        items: [createItem(PAGE_TITLES.CATALOG.title, ROUTES.CATALOG.path)],
      },
      {
        id: 'settings',
        name: PAGE_TITLES.SETTINGS.title,
        icon: <EuiIcon type="gear" />,
        forceOpen: true,
        items: [
          createItem(PAGE_TITLES.SETTINGS.ATTRIBUTES.title, ROUTES.SETTINGS.ATTRIBUTES.path),
          createItem(
            PAGE_TITLES.SETTINGS.ATTRIBUTE_GROUPS.title,
            ROUTES.SETTINGS.ATTRIBUTE_GROUPS.path,
          ),
        ],
      },
    ],
  },
]
