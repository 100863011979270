import { API_QUERY_KEY, useQuery } from '@shared/api'

import { type ApiSkuItem } from '../types'
import { getSku } from './get-sku'

export const useSkuApi = (id: string = '') => {
  return useQuery<ApiSkuItem>([API_QUERY_KEY.SKU_LIST, parseInt(id)], () => {
    return getSku(id)
  })
}
