import { Document, Font, Image, Page, StyleSheet, Text, View } from '@react-pdf/renderer'
import { CATALOG_TABLE_IMAGE_SIZE } from '@shared/config'

import { type SkuPrintDocumentProps } from './types'

Font.register({
  family: 'Roboto',
  fonts: [
    {
      src: 'https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-regular-webfont.ttf',
      fontWeight: 'normal',
    },
    {
      src: 'https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-medium-webfont.ttf',
      fontWeight: 'bold',
    },
  ],
})

const styles = StyleSheet.create({
  body: {
    paddingTop: 25,
    paddingBottom: 65,
    paddingHorizontal: 35,
    fontFamily: 'Roboto',
    fontWeight: 'normal',
  },
  title: {
    paddingTop: 10,
    fontSize: '16px',
    fontWeight: 'bold',
  },
  subtitle: {
    paddingTop: 5,
    fontWeight: 'bold',
  },
  text: {
    fontSize: '12px',
  },
  image: {
    width: CATALOG_TABLE_IMAGE_SIZE,
  },
})

export const SkuPrintDocument: ReactFc<SkuPrintDocumentProps> = ({ attributesGroupsList }) => {
  return (
    <Document>
      <Page size="A4" style={styles.body}>
        {attributesGroupsList.map((attributesGroup) => {
          return (
            <View key={attributesGroup.id}>
              <Text style={styles.title}>{attributesGroup.name}</Text>

              {attributesGroup.attributes.map((attribute) => {
                if (attribute.is_preview && attribute.file) {
                  return (
                    <View key={attribute.name} style={styles.text}>
                      <Text style={styles.subtitle}>{attribute.name}: </Text>
                      <Image src={attribute.file.preview} style={styles.image} />
                    </View>
                  )
                }

                return (
                  <View key={attribute.name} style={styles.text}>
                    <Text style={styles.subtitle}>{attribute.name}: </Text>
                    <Text>{attribute.value || '–'}</Text>
                  </View>
                )
              })}
            </View>
          )
        })}
      </Page>
    </Document>
  )
}
