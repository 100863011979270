import { type ApiCategoryItem, useCategoryListApi } from '@entities/catalog'

const useCategoryOptions = () => {
  const { data: categories = [], isLoading } = useCategoryListApi()

  const convertCategories = (categories: ApiCategoryItem[]) => {
    return categories.map(({ id, attributes }) => {
      const name = attributes.find((attribute) => {
        return attribute.is_name
      })?.value

      return {
        value: id,
        inputDisplay: name,
      }
    })
  }

  const categoryOptions = convertCategories(categories)
  categoryOptions.unshift({ value: 0, inputDisplay: 'Корневая категория' })

  return { categoryOptions, isLoading }
}

export { useCategoryOptions }
