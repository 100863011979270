import { EuiFormRow, EuiSelect } from '@elastic/eui'
import { useCatalogListApi } from '@entities/catalog'
import { useRouterParams } from '@shared/hooks'
import { useEffect } from 'react'

import { createCatalogSelectOptions } from '../model'

export const CatalogSelect: ReactFc = () => {
  const { hasParam, getParamId, setParam, deleteParam } = useRouterParams()
  const { isLoading, data: catalogs = [], hasCatalogs } = useCatalogListApi()

  // Если на странице каталога нет параметра id ставим первый каталог
  useEffect(() => {
    if (!hasParam('id') && hasCatalogs) {
      setParam('id', catalogs[0].id)
    }
  }, [hasParam('id'), hasCatalogs])

  const catalogId = getParamId() ?? undefined
  const catalogsOptions = createCatalogSelectOptions({ catalogs, isLoading, hasCatalogs })

  const handleSelect: React.ChangeEventHandler<HTMLSelectElement> = (event) => {
    setParam('id', event.target.value)
    deleteParam('category')
  }

  return (
    <EuiFormRow label="Выбирите каталог">
      <EuiSelect
        isLoading={isLoading}
        value={catalogId}
        options={catalogsOptions}
        onChange={handleSelect}
        disabled={!hasCatalogs}
      />
    </EuiFormRow>
  )
}
