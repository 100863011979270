import { type EuiTableSelectionType } from '@elastic/eui'
import { type ApiAttribute } from '@shared/api/types'
import { useRouterParams } from '@shared/hooks'

export const useSelectionTable = <T extends ApiAttribute>() => {
  const { setParam, deleteParam, getParamValues } = useRouterParams()

  const selection: EuiTableSelectionType<T> = {
    onSelectionChange: (selection) => {
      if (!selection.length) {
        deleteParam('selected')
      } else {
        setParam(
          'selected',
          selection.map((item) => {
            return item.id
          }),
        )
      }
    },
    initialSelected: getParamValues('selected').map((item) => {
      return {
        id: parseInt(item),
      } as T
    }),
  }

  return {
    selection,
  }
}
