import { EuiHeaderLink } from '@elastic/eui'
import { MESSAGE } from '@shared/config'

import { useRemoveAttributeGroups } from '../model'

const RemoveAttributeGroups: ReactFc = () => {
  const { isDisabledButton, isSubmitting, removeFromGroups } = useRemoveAttributeGroups()

  return (
    <EuiHeaderLink
      iconType="trash"
      color="primary"
      iconSide="right"
      onClick={removeFromGroups}
      isLoading={isSubmitting}
      disabled={isDisabledButton}
    >
      {MESSAGE.DELETE_SELECTED}
    </EuiHeaderLink>
  )
}

export { RemoveAttributeGroups }
