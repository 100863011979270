import { EuiHeader, EuiHeaderSection, EuiHeaderSectionItem } from '@elastic/eui'
import { AddAttributeGroup, RemoveAttributeGroups } from '@features/attributes'
import { noShadow } from '@shared/ui/styles'

export const AttributeGroupsHeader: ReactFc = () => {
  return (
    <EuiHeader css={noShadow}>
      <EuiHeaderSection>{/* TODO: Добавить поиск по группам */}</EuiHeaderSection>

      <EuiHeaderSectionItem>
        <RemoveAttributeGroups />
        <AddAttributeGroup />
      </EuiHeaderSectionItem>
    </EuiHeader>
  )
}
