import { API_QUERY_KEY, queryClient } from '@shared/api'
import { type ApiAddSku } from '@shared/api/types'
import { MESSAGE } from '@shared/config'
import { useMutation } from '@tanstack/react-query'
import { useForm } from 'react-hook-form'

import { addSkuApi } from '../api'
import { type AddSkuFormInputs } from '../types'

const useAddSku = (onClose: EmptyCallback) => {
  const methods = useForm<AddSkuFormInputs>()

  const handleModalClose = () => {
    methods.reset()
    onClose()
  }

  const { mutate, isPending } = useMutation({
    mutationFn: async (fieldsData: ApiAddSku) => {
      const newSku = await addSkuApi(fieldsData)

      return newSku
    },
    onError: () => {
      methods.setError('root', {
        type: 'manual',
        message: MESSAGE.SERVER_ERROR,
      })
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [API_QUERY_KEY.SKU_LIST] })
      onClose()
    },
  })

  const handleSubmit = methods.handleSubmit((fieldsData) => {
    mutate(fieldsData)
  })

  return {
    handleModalClose,
    methods,
    handleSubmit,
    isLoading: isPending,
  }
}

export { useAddSku }
