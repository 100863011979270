import { EuiIcon } from '@elastic/eui'

import { type CategoryFilterTree, type CategoryFilterTreeProps } from '../types'
import { CategoryFilterCheckbox } from './category-filter-checkbox'
import { CategoryFilterItem } from './category-filter-item'

export const getCategoryFilterTree = ({
  items,
  enableCheckbox = false,
  enableRoot = false,
  isAttributesCategory = false,
}: CategoryFilterTreeProps): CategoryFilterTree[] => {
  let categoryFilterTree = items.map((item) => {
    const { id, name, skus_count, childNodes, isExpanded, children } = item

    return {
      id: `category_${id}`,
      label: enableCheckbox ? (
        <CategoryFilterCheckbox
          value={id}
          label={name}
          skus_count={skus_count}
          childNodes={childNodes}
        />
      ) : (
        <CategoryFilterItem
          name={name}
          skus_count={skus_count}
          value={id}
          isAttributesCategory={isAttributesCategory}
        />
      ),
      icon: isAttributesCategory ? <EuiIcon type="folderClosed" /> : null,
      iconWhenExpanded: isAttributesCategory ? <EuiIcon type="folderOpen" /> : null,
      isExpanded: isExpanded,
      children:
        children &&
        getCategoryFilterTree({ items: children, enableCheckbox, isAttributesCategory }),
    }
  })

  if (enableRoot) {
    const rootCount = items.reduce((accumulator, item) => {
      return accumulator + item.skus_count
    }, 0)

    categoryFilterTree = [
      {
        id: 'category_root',
        label: (
          <CategoryFilterItem
            name="Корневая категория"
            skus_count={rootCount}
            value={0}
            isRoot
            isAttributesCategory={isAttributesCategory}
          />
        ),
        icon: <EuiIcon type="folderClosed" />,
        iconWhenExpanded: <EuiIcon type="folderOpen" />,
        isExpanded: true,
        children: categoryFilterTree,
      },
    ]
  }

  return categoryFilterTree
}
