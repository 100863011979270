import { EuiFieldSearch } from '@elastic/eui'
import { useRouterParams } from '@shared/hooks'
import { useDebounce } from '@uidotdev/usehooks'
import { useEffect, useState } from 'react'

import { type SearchProps } from './type'

const Search: ReactFc<SearchProps> = ({ searchParam, ...props }) => {
  const { setParam, getParamValues, deleteParam } = useRouterParams()
  const value = getParamValues(searchParam).toString() || ''
  const [searchValue, setSearchValue] = useState(value)
  const debouncedSearchValue = useDebounce(searchValue, 500)

  const changeParam = () => {
    if (searchValue) {
      setParam(searchParam, searchValue)
    } else {
      deleteParam(searchParam)
    }
  }

  useEffect(() => {
    changeParam()
  }, [debouncedSearchValue])

  const onFieldChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    setSearchValue(event.target.value)
  }

  return (
    <EuiFieldSearch
      value={searchValue}
      isClearable
      {...props}
      onChange={onFieldChange}
      compressed
    />
  )
}

export { Search }
