import createFetchClient, { type Middleware } from 'openapi-fetch'
import createClient from 'openapi-react-query'

import { API_BASE, DEFAULT_HEADERS } from './constans'
// import { forbiddenHandler } from './lib'
import { type UpdatedPaths } from './types'

const throwOnError: Middleware = {
  async onResponse({ response }) {
    // Обработка 403 ошибки TODO: Разобраться с 403 ошибками
    // forbiddenHandler(response)

    // TODO: Добавить обработку ошибок
    if (response.status >= 400) {
      console.error(response)
    }

    return undefined
  },
}

const fetchClient = createFetchClient<UpdatedPaths<typeof API_BASE>>({
  headers: DEFAULT_HEADERS,
  baseUrl: API_BASE,
})

fetchClient.use(throwOnError)

const $api = createClient(fetchClient)

export { $api }
